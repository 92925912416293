import React from "react"

import { Stack, Text, TextLink } from "@kiwicom/orbit-components"

import {
  urlForChecklist,
  urlForIssue,
} from "components/maintenance/shared/urls"

import { checklistTypeToLabel } from "types/checklist"

import {
  formatAsMachineReadableDate,
  formatAsMediumDate,
  formatAsShortDateTime,
  getDefaultTimezone,
} from "utils/date-utils"
import { penniesInToPoundsStr } from "utils/string-utils"

import { ProblemClasses } from "./types"

interface ProblemContent {
  occursAt: Date
  details: any
}

const urlForTrip = (tripDate: string | Date, tripId: number): string => {
  const dateStr = formatAsMachineReadableDate(tripDate, getDefaultTimezone())
  return `/account/admin/?date=${dateStr}&trip=${tripId}`
}

const NoVehicleAssignedToTripContent = ({
  occursAt,
  details,
}: ProblemContent) => {
  return (
    <TextLink size="large" href={urlForTrip(occursAt, details.tripId)} external>
      {details.tripName}
    </TextLink>
  )
}

const UnassignedShifts = ({ occursAt, details }: ProblemContent) => {
  const date = formatAsMachineReadableDate(occursAt, getDefaultTimezone())
  return (
    <Stack direction="row">
      <TextLink
        size="large"
        href={`/account/admin/shifts/?date=${date}&shift=${details.shiftId}`}
        external
      >
        {formatAsShortDateTime(details.shiftStart, getDefaultTimezone())}
      </TextLink>
      <Text type="secondary" size="small">
        Paid at {details.payRate}x
      </Text>
    </Stack>
  )
}

const PassengersAtInactiveStops = ({ occursAt, details }: ProblemContent) => (
  <Stack direction="row">
    <Text>
      Reservation ID {details.reservationId} using {details.locationName} on{" "}
      {formatAsShortDateTime(occursAt, getDefaultTimezone())}
    </Text>
  </Stack>
)

const PassengerCapacityIssue = ({ occursAt, details }: ProblemContent) => {
  return (
    <Stack direction="column">
      <TextLink href={urlForTrip(occursAt, details.tripId)} external>
        {formatAsShortDateTime(occursAt, getDefaultTimezone())}
      </TextLink>
      <Stack direction="row">
        {details.capacityDifferences.map((difference) => (
          <Text key={difference.resourceType}>
            {difference.requiredCapacity} {difference.resourceType} required,{" "}
            {difference.availableCapacity} available
          </Text>
        ))}
      </Stack>
    </Stack>
  )
}

const ShiftRuleViolations = ({ occursAt, details }: ProblemContent) => {
  const start = formatAsMachineReadableDate(
    details.startTime,
    getDefaultTimezone()
  )
  const end = formatAsMachineReadableDate(details.endTime, getDefaultTimezone())
  let link = `/account/admin/rota/?range=${start}_${end}`
  const name = details.extras?.driverName
    ? ` - ${details.extras.driverName}`
    : ""
  if (details.extras?.shiftId) {
    link = `/account/admin/shifts/?date=${start}&shift=${details.extras.shiftId}`
  }
  return (
    <Stack direction="column">
      <TextLink href={link} external>
        {formatAsShortDateTime(
          details.startTime ? details.startTime : occursAt,
          getDefaultTimezone()
        )}
      </TextLink>
      <Text>
        {details.code}
        {name}
      </Text>
    </Stack>
  )
}

const ShiftActivityLocationInconsistent = ({
  occursAt,
  details,
}: ProblemContent) => {
  const link = `/account/admin/shifts/?shift=${details.shiftId}`
  return (
    <Stack direction="column">
      <Text>
        Shift on&nbsp;
        <TextLink href={link} external>
          {formatAsShortDateTime(occursAt, getDefaultTimezone())}
        </TextLink>
        &nbsp;has activities without a continuous location history
      </Text>
    </Stack>
  )
}

const TripNotAssociatedToAShift = ({ occursAt, details }: ProblemContent) => {
  const datetime = formatAsShortDateTime(occursAt, getDefaultTimezone())
  return (
    <Stack direction="column">
      <TextLink href={urlForTrip(occursAt, details.tripId)} external>
        {details.locationName} on {datetime}
      </TextLink>
    </Stack>
  )
}

const TimeOffHangingTooLong = ({ details }: ProblemContent) => {
  return (
    <Stack spacing="XXSmall">
      <Stack direction="row">
        <Text>{details.employeeName}</Text>
        <TextLink href="/account/admin/staff/leave/" external>
          {details.problem}
        </TextLink>
      </Stack>
    </Stack>
  )
}

const LeaveAllowanceProblem = ({ details }: ProblemContent) => {
  return (
    <Stack spacing="XXSmall">
      <Stack direction="row">
        <TextLink
          href={`/account/admin/staff/?driver=${details.employeeUid}`}
          external
        >
          {details.employeeName}
        </TextLink>
        <Text>{details.problem}</Text>
      </Stack>
      {details.expectedMinutes && (
        <Text>
          Expected {Number(details.expectedMinutes / 60).toFixed(1)} hours
          entitlement, but found {Number(details.actualMinutes / 60).toFixed(1)}
          .
        </Text>
      )}
    </Stack>
  )
}

const BatteryCapacityIssue = ({ occursAt, details }: ProblemContent) => {
  return (
    <Text>
      {details.vehicleName} has insufficient range for the trip on{" "}
      <TextLink href={urlForTrip(occursAt, details.tripId)} external>
        {formatAsShortDateTime(occursAt, getDefaultTimezone())}
      </TextLink>
    </Text>
  )
}

const VehicleAssignedToMultipleTrips = ({ details }: ProblemContent) => {
  return (
    <Text>
      A vehicle is assigned to overlapping trips. One starting on{" "}
      <TextLink
        href={urlForTrip(details.firstTripStart, details.firstTripId)}
        external
      >
        {formatAsShortDateTime(details.firstTripStart, getDefaultTimezone())}
      </TextLink>{" "}
      and another starting on{" "}
      <TextLink
        href={urlForTrip(details.secondTripStart, details.secondTripId)}
        external
      >
        {formatAsShortDateTime(details.secondTripStart, getDefaultTimezone())}
      </TextLink>
    </Text>
  )
}

const VehicleOffRoadButAssignedToTrip = ({
  occursAt,
  details,
}: ProblemContent) => {
  return (
    <Text>
      {details.vehicleName} is due out on{" "}
      <TextLink href={urlForTrip(occursAt, details.tripId)} external>
        {formatAsShortDateTime(occursAt, getDefaultTimezone())}
      </TextLink>{" "}
      with an{" "}
      <TextLink href={urlForIssue({ uid: details.issueUid })} external>
        issue
      </TextLink>{" "}
      making it unsafe to drive.
    </Text>
  )
}

const NewCriticalIssue = ({ occursAt, details }: ProblemContent) => {
  return (
    <Text>
      {details.vehicleName} has{" "}
      <TextLink href={urlForIssue({ uid: details.issueUid })} external>
        an unsafe issue
      </TextLink>{" "}
      logged on {formatAsShortDateTime(occursAt, getDefaultTimezone())} that
      needs triage.
    </Text>
  )
}

const VehicleInServiceMayBecomeUnusable = ({
  occursAt,
  details,
}: ProblemContent) => {
  return (
    <Text>
      {details.vehicleName} is due out on{" "}
      {formatAsShortDateTime(occursAt, getDefaultTimezone())}
      {" but it has "}
      <TextLink href={urlForIssue({ uid: details.issueUid })} external>
        an issue that may become unsafe
      </TextLink>{" "}
      needing triage.
    </Text>
  )
}

const OverdueChecklist = ({ details }: ProblemContent) => (
  <Text>
    <TextLink href={urlForChecklist({ uid: details.checklistUid })} external>
      {checklistTypeToLabel(details.checklistType)}
      {" for "}
      {details.vehicleName}
    </TextLink>{" "}
    is overdue.
  </Text>
)

const ChecklistDueSoonNotProperlyScheduled = ({ details }: ProblemContent) => (
  <Text>
    <TextLink href={urlForChecklist({ uid: details.checklistUid })} external>
      {checklistTypeToLabel(details.checklistType)}
      {" for "}
      {details.vehicleName}
    </TextLink>{" "}
    is incomplete and due on{" "}
    {formatAsMediumDate(details.deadline, getDefaultTimezone())}.
    {details.problemSubtype == "scheduled_after_deadline"
      ? `
        There is work for it that is scheduled to end after the deadline, suggesting it won't be done in time.
      `
      : details.problemSubtype == "activities_are_long_past"
      ? `
        There has been work for it scheduled in the past.
        If that work was done, the job should be completed.
        If not, it should be rescheduled.
      `
      : " Work for it has not been scheduled."}
  </Text>
)

const MissingVehicleInspection = ({ details, occursAt }: ProblemContent) => (
  <Text>
    {details.vehicleName} is due out on{" "}
    <TextLink href={urlForTrip(occursAt, details.nextDepartureTripId)} external>
      {formatAsShortDateTime(occursAt, getDefaultTimezone())}
    </TextLink>{" "}
    without a valid vehicle inspection.
  </Text>
)

const MissingMot = ({ details, occursAt }: ProblemContent) => (
  <Text>
    {details.vehicleName} is due out on{" "}
    <TextLink href={urlForTrip(occursAt, details.nextDepartureTripId)} external>
      {formatAsShortDateTime(occursAt, getDefaultTimezone())}
    </TextLink>{" "}
    without a valid MOT.{" "}
    {details.nextMotDue
      ? `The current MOT is valid until ${formatAsMediumDate(
          details.nextMotDue,
          getDefaultTimezone()
        )}.`
      : "There is no valid MOT for this vehicle. If this is a new vehicle, the RegistrationData is probably missing."}
  </Text>
)

const ActivitiesOverlapWithTrip = ({ details, occursAt }: ProblemContent) => (
  <Text>
    {details.vehicleName}'s trip on{" "}
    <TextLink href={urlForTrip(occursAt, details.tripId)} external>
      {formatAsShortDateTime(occursAt, getDefaultTimezone())}
    </TextLink>{" "}
    overlaps with scheduled maintenance activities.
  </Text>
)

const OrderAccountNotBalanced = ({ details }: ProblemContent) => (
  <Text>
    <TextLink href={`/confirm/?order=${details.orderUid}`} external>
      Order
    </TextLink>{" "}
    with net payment of{" "}
    {penniesInToPoundsStr(
      details.payed - details.refundedToCard - details.refundedToStoreCredit
    )}{" "}
    and net tickets value of{" "}
    {penniesInToPoundsStr(details.ticketsValue - details.ticketsRefundedValue)}.
  </Text>
)

export function getProblemContent(type: ProblemClasses) {
  return {
    not_enough_time_to_charge_before_trip: null,
    insufficient_passenger_capacity: PassengerCapacityIssue,
    no_vehicle_assigned_to_trip: NoVehicleAssignedToTripContent,
    vehicle_assigned_to_multiple_trips_at_the_same_time:
      VehicleAssignedToMultipleTrips,
    trip_not_associated_to_a_shift: TripNotAssociatedToAShift,
    outbound_and_inbound_vehicles_differ: null,
    shift_does_not_have_a_driver: UnassignedShifts,
    insufficient_battery_capacity: BatteryCapacityIssue,
    passengers_booked_at_inactive_stops: PassengersAtInactiveStops,
    shift_rule_violations: ShiftRuleViolations,
    leave_allowance_problem: LeaveAllowanceProblem,
    leave_account_problem: LeaveAllowanceProblem,
    leave_account_closing_problem: LeaveAllowanceProblem,
    time_off_request_hanging_too_long: TimeOffHangingTooLong,
    vehicle_off_road_but_assigned_to_trip: VehicleOffRoadButAssignedToTrip,
    new_critical_issue: NewCriticalIssue,
    vehicle_in_service_may_become_unusable: VehicleInServiceMayBecomeUnusable,
    overdue_checklist: OverdueChecklist,
    checklist_due_soon_not_properly_scheduled:
      ChecklistDueSoonNotProperlyScheduled,
    missing_vehicle_inspection: MissingVehicleInspection,
    missing_mot: MissingMot,
    activities_overlap_with_trip: ActivitiesOverlapWithTrip,
    order_account_not_balanced: OrderAccountNotBalanced,
    shift_activity_location_problem: ShiftActivityLocationInconsistent,
  }[type]
}
